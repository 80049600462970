<template>
	<div id="appPage">
		<keep-alive>
			<router-view v-if="currentRoute.keepAlive"></router-view>
		</keep-alive>
		<router-view v-if="!currentRoute.keepAlive"></router-view>
	</div>
</template>
<script>
export default {
	computed: {
		currentRoute() {
			return this.$route.meta
		}
	}
}
</script>
<style lang="scss" scoped>
#appPage {
	width: 100%;
	height: 100%;
}
</style>
